/* fonts */
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('../src/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'AmaticSC';
	src: local('AmaticSC'), url('../src/fonts/AmaticSC-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Courgette';
	src: local('Courgette'), url('../src/fonts/Courgette-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'DelaGothicOne';
	src: local('DelaGothicOne'), url('../src/fonts/DelaGothicOne-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Neucha';
	src: local('Neucha'), url('../src/fonts/Neucha-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Parisienne';
	src: local('Parisienne'), url('../src/fonts/Parisienne-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: local('PlayfairDisplay'), url('../src/fonts/PlayfairDisplay-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Montserrat', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
